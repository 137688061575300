<template>
  <div
    class="filter-text"
    :class="{ 'is-hidden': isHidden }"
  >
    <form
      type="submit"
      class="filter-text__form"
      @submit.prevent="onSubmit"
    >
      <FormInputText
        class="filter-text__input"
        :data="{
          field_key: 'searchString',
          label: data.label,
          is_required: false,
        }"
        :prefill-value="prefillValue ? prefillValue : data.prefill_value"
        :is-auto-complete="false"
        :is-general-auto-complete="isGeneral"
        :is-background-white="false"
        @emit-value="setData"
      />

      <AppIcon
        class="filter-text__icon"
        icon="search"
        :is-raw="true"
        @click="onSubmit"
      />
    </form>
  </div>
</template>

<script setup>
const emit = defineEmits(['set-text-search']);
const route = useRoute();

const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
    isHidden: {
        type: Boolean,
        default: false,
    },
    isGeneral: {
        type: Boolean,
        default: true,
    },
});

const prefillValue = computed(() => {
    const { search: searchString } = route.query;
    if (searchString) {
        return searchString;
    }

    return props.data.prefill_value;
});

const formData = {};

const onSubmit = () => {
    emit('set-text-search', {
        values: formData.searchString,
        isInitial: false,
    });
};

const setData = (values) => {
    formData[values.key] = values.value;
    onSubmit();
};
</script>

<style lang="scss" scoped>
.filter-text {
    @include grid-columns(1, var(--grid-columns));

    position: relative;
    display: flex;
    width: 100%;
    max-width: 537px;
    margin-bottom: 10px;

    &.is-hidden {
        display: none;
    }
}

.filter-text__icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 20px;
    transform: translateY(-50%);
}

.filter-text__input {
    width: 100%;
    margin: 0;
}

.filter-text__form {
    width: 100%;
}
</style>
